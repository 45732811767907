import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable, ReplaySubject, shareReplay} from "rxjs";
import {User} from "../_models/user";
import {Library} from "../_models/library/library";
import {tap} from "rxjs/operators";
import { DirectoryDto } from '../_models/system/directory-dto';

@Injectable({
  providedIn: 'root'
})
export class LibraryService {

  baseUrl = environment.apiUrl + 'library/';

  private librariesSource = new ReplaySubject<Library[]>(1);
  public libraries$ = this.librariesSource.asObservable().pipe(shareReplay({bufferSize: 1, refCount: true}));

  constructor(private httpClient: HttpClient) { }

  getLibraries() {
    return this.httpClient.get<Library[]>(this.baseUrl + 'libraries').pipe(tap(res => {
      this.librariesSource.next(res);
    }));
  }

  libraryNameExists(name: string) {
    return this.httpClient.get<boolean>(this.baseUrl + 'name-exists?name=' + name);
  }

  create(model: {name: string, type: number, folders: string[]}) {
    return this.httpClient.post(this.baseUrl + 'create', model);
  }

  delete(libraryId: number) {
    return this.httpClient.delete(this.baseUrl + 'delete?libraryId=' + libraryId, {});
  }

  update(model: {name: string, folders: string[], id: number}) {
    return this.httpClient.post(this.baseUrl + 'update', model);
  }

  scan(libraryId: number, force = false) {
    return this.httpClient.post(this.baseUrl + 'scan?libraryId=' + libraryId + '&force=' + force, {});
  }

  listDirectories(rootPath: string) {
    let query = '';
    if (rootPath !== undefined && rootPath.length > 0) {
      query = '?path=' + encodeURIComponent(rootPath);
    }

    return this.httpClient.get<DirectoryDto[]>(this.baseUrl + 'list' + query);
  }

}
